<template>
  <div>
    <restaurants-leisures-component />
    <footer-component />
  </div>
</template>

<script>
import RestaurantsLeisuresComponent from "@/components/restaurants-leisures/RestaurantsLeisuresComponent";
import FooterComponent from "@/layouts/app-layout/footer/FooterComponent.vue";
import { changeInformationSEO } from "@/providers/KeywordsServices";

export default {
  name: "RestaurantsLeisures",
  title: "Restaurantes y Entretenimientos | Baja California Health Tourism",
  components: {
    RestaurantsLeisuresComponent,
    FooterComponent,
  },
  mounted(){
    changeInformationSEO();
  }
};
</script>

<style scoped></style>
